import Header from "../Header/Header";
import HowItWorks from "../HowItWorks/HowItWorks";
import Footer from "../Footer/Footer";
import "./Home.css";
import FaqSection from "../FaqSection/FaqSection";
import InvestorsMarquee from "../InvestorsMarquee/InvestorsMarquee";
import Guarantees from "../Guarantees/Guarantees";
import Carousel from "../Carousel/Carousel";
import { useRef } from "react";
import Nav from "../Nav/Nav";
import BannerButton from "../BannerButton/BannerButton";

const Home = () => {
  const homeRef = useRef(null);
  const featuresRef = useRef(null);
  const faqRef = useRef(null);

  return (
    <main className="home-main-style" ref={homeRef}>
      <Header />
      <InvestorsMarquee />
      <HowItWorks />
      <Carousel ref={featuresRef} />
      <Guarantees />
      <FaqSection ref={faqRef} />
      <div className="home-top-banner-btn">
        <BannerButton
          buttonText={"More Questions? Join our Discord!"}
          link={"https://discord.gg/SbchSVAuFB"}
        />
      </div>
      <Footer />
      <Nav homeRef={homeRef} featuresRef={featuresRef} faqRef={faqRef} />
    </main>
  );
};

export default Home;
