// import {Dimensions} from 'react-native'
// const {width, height} = Dimensions.get('window')
// \
export const COLORS = {
  // base colors
  // dark: '#252525', // dark
  dark: "#121212",
  orange: "#FF7C14", // orange
  primarySuccess: "#AFE786", // dark blue

  // fireOrange: '#FF7700',
  fireOrange: "#FF7700",

  darkLevelOne: "#1e1e1e",
  darkLevelTwo: "#424242",
  disabledGray: "#9E9E9E",

  fireOrangeGradient: ["#f12711", "#FF7700", "#f5af19"],
  textWhite: "#FAFAFA",

  green: "#4caf50",
  red: "#e53935",

  // colors
  black: "#1E1F20",
  white: "#FFFFFF",
  light: "#F5F5F6",
  lightGray: "#FAFAFB",
  lightGray2: "#F6F6F7",
  lightGray3: "#EFEFF1",
  lightGray4: "#50555C", // Grey/80
  transparent: "transparent",
  darkgray: "#898C95",
  textInputGray: "#7C7C7C",
  textInputValueWhite: "#D3D3D3",
};

export const SIZES = {
  // global sizes
  base: 5,
  font: 14,
  radius: 30,
  padding: 10,
  padding2: 12,
  padding3: 24,
  base2: 8,
  // font sizes
  largeTitle: 50,
  h1: 30,
  h2: 22,
  h3: 20,
  h4: 18,
  body1: 30,
  body2: 20,
  body3: 16,
  body4: 14,
  body5: 12,

  // // app dimensions
  // width,
  // height,
};
