// import Marquee, { Motion, randomIntFromInterval } from "react-marquee-slider";
// import times from "lodash/times";

import "./InvestorsMarquee.css";
import western from "../../assets/investor-logos/gray/WesternUnion.png";
import techstars from "../../assets/investor-logos/gray/Techstars.png";
import lightspeed from "../../assets/investor-logos/gray/Lightspeed.png";
import harlem from "../../assets/investor-logos/gray/HarlemCapital.png";
import hustle from "../../assets/investor-logos/gray/Hustlefund.png";
import plug from "../../assets/investor-logos/gray/PlugandPlay.png";
import { useEffect, useState } from "react";

import React from "react";
import Marquee from "react-marquee-slider";
import times from "lodash/times";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

const InvestorsMarquee = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 600;
  const vel = isMobile ? 10 : 55;
  const [investors, setInvestors] = useState([
    western,
    techstars,
    lightspeed,
    harlem,
    hustle,
    plug,
  ]);
  useEffect(() => {
    setInvestors([western, techstars, lightspeed, harlem, hustle, plug]);
  }, []);
  return (
    <div className="marquee-container">
      <Marquee velocity={vel}>
        {times(6, Number).map((id) => (
          <img
            alt=""
            className="investor-photo"
            src={investors[id]}
            key={`marquee-example-people-${id}`}
            style={
              isMobile
                ? {
                    marginLeft: "50px",
                    marginRight: "50px",
                    height: "100px",
                    // width: "200px",
                  }
                : {
                    marginLeft: "100px",
                    marginRight: "100px",
                    height: "200px",
                  }
            }
          />
        ))}
      </Marquee>
    </div>
  );
};

export default InvestorsMarquee;
