// import phones from "../../assets/phone-mock.png";
import "./Guarantees.css";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { AnimationOnScroll } from "react-animation-on-scroll";
// import phoneCardLottie from "../../assets/lotties/phone-card-lottie.json";
// import phoneStockLottie from "../../assets/lotties/stock-app.json";
// import fractionalLottie from "../../assets/lotties/fractional.json";
// import gainsLottie from "../../assets/lotties/gains.json";

// import secIcon from "../../assets/icons/clipboard.png";
import bankIcon from "../../assets/icons/bank.png";
import insuranceIcon from "../../assets/icons/insurance.png";

// import Lottie from "lottie-react";

import "animate.css/animate.min.css";

const Guarantees = () => {
  return (
    <div className="guarantees-container">
      <h2 className="guarantees-header">We Got Your Back</h2>
      <Row lg={2}>
        {/* <Col>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="guarantees-section ">
              <img
                src={secIcon}
                alt="step1"
                className="guarantees-section-img"
              />
             
              <h3 className="step-number">SEC Registered</h3>
              <p className="step-description">
                We are a Registered Investment Advisor with the SEC. This means
                we must provide investment advice that is in your best interest.
              </p>
            </div>
          </AnimationOnScroll>
        </Col> */}
        <Col>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="guarantees-section ">
              <img
                src={insuranceIcon}
                alt="step1"
                className="guarantees-section-img"
              />
              {/* <Lottie
                animationData={phoneStockLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">FINRA and SIPC Insured</h3>
              <p className="step-description">
                Our partner Alpaca is a member of FINRA and SIPC, and your
                account is protected up to $500,000.
              </p>
            </div>
          </AnimationOnScroll>
        </Col>
        <Col>
          <AnimationOnScroll animateIn="animate__fadeIn">
            <div className="guarantees-section ">
              <img
                src={bankIcon}
                alt="step1"
                className="guarantees-section-img"
              />
              {/* <Lottie
                animationData={fractionalLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">
                Bank-level Security and Encryption
              </h3>
              <p className="step-description">
                We work with Plaid to fully protect your information.
              </p>
            </div>
          </AnimationOnScroll>
        </Col>
      </Row>
    </div>
  );
};

export default Guarantees;
