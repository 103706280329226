import React from "react";
import logo from "../../assets/staax-orange.png";
import { COLORS } from "../../theme";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

const Legal = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 600;
  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundColor: COLORS.dark,
          height: "55px",
        }}
      >
        <a href="/">
          <img
            className="topnav-logo"
            src={logo}
            alt="staax"
            style={{
              height: "35px",
              position: "fixed",
              top: "10px",
              left: "10px",
            }}
          />
        </a>
      </div>

      <div
        style={
          isMobile
            ? {
                color: "white",
                margin: "5%",
                marginTop: "100px",
                // marginLeft: "5%",
                // marginRight: "5%",
                // marginBottom: ""

                textAlign: "left",
              }
            : {
                color: "white",
                marginTop: "100px",
                marginLeft: "25%",
                marginRight: "25%",

                textAlign: "left",
              }
        }
      >
        <div class="max-w-3xl mx-auto py-20 px-6">
          <h1 class="text-5xl pb-2 text-center font-bold text-blackish">
            Terms of Use – Staax, Inc
          </h1>
          <p class="text-blackish text-lg font-bold text-center pb-24">
            Effective Date: Sept 1, 2021
            <br />
            Last Updated: Sept 20, 2022
          </p>
          <div>
            Our website and mobile application is provided by Staax, Inc.
            (“Staax,” “we,” or “us”). These terms of use ("Terms") constitute a
            legal contract between you and Staax and govern all aspects of your
            use of Staax’s website and mobile application (“Site”). Please read
            these Terms carefully. By downloading or using any of the
            information, materials, or functions available on the Site (the
            “Services”), you agree to be bound by these Terms.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Your Information and Disclosure to Third Parties
            </h1>
            Staax has made a commitment to protecting the privacy of those who
            use our Site. Please review the Site’s Privacy Policy. The Site’s
            Privacy Policy is incorporated by reference in the Terms. By using
            the Site, you are consenting to the Privacy Policy. When you use the
            Site, you consent to receive communications from us electronically
            via the Site. You agree to provide accurate, current, and complete
            information about yourself when you use the Site and to update such
            information while you continue to use the Site.
            <h1 class="text-xl pb-2  font-bold text-blackish">Eligibility</h1>
            You represent and warrant that you: (a) are above the legal age of
            majority in your jurisdiction of residence, (b) have not previously
            been suspended or removed from the Site, (c) do not have more than
            one (1) account at any given time for the Site; (d) you will only
            provide us with true, accurate, current and complete information if
            you register for an account. If we believe or suspect that your
            information is not true, accurate, current or complete, we may deny
            or terminate your access to the Site or Services (or any portion
            thereof), and (e) that you have full power and authority to enter
            into the Terms and in doing so will not violate any other agreement
            to which you are a party.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Accounts and Registration
            </h1>
            To access the Service, you must register for an account. When you
            register you will be required to provide us with some information
            about yourself. You agree that the information you provide to us is
            accurate and that you will keep it accurate and up-to-date at all
            times. We reserve the right to restrict certain areas of information
            on the Site to such registered users. If you provide any information
            that is untrue, inaccurate, not current or incomplete, or we have
            reasonable grounds to suspect such, we reserve the right to
            terminate your account and refuse any and all current or future use
            of the Site by you. When you register, you will be asked to create a
            PIN. You are responsible for maintaining the confidentiality of your
            account and PIN. Staax is not liable for any harm related to the
            theft of your access credentials, your disclosure of access
            credentials, or your decision to allow another person or entity to
            access and use the Site using your access credentials. You agree to
            accept responsibility for all activities that occur under your
            account. If you have reason to believe that your account is no
            longer secure, then you must immediately notify us by using the
            contact information below.
            <h1 class="text-xl pb-2  font-bold text-blackish">Investments</h1>
            You should carefully read these Terms and consider the investment
            objectives, risks, charges and expenses of Staax before investing.
            As with many investment programs, there can be no assurance that the
            value of your investment will grow at any particular rate or that it
            will not decline. For more information about our investment
            strategies, contact us using the information below. Information
            regarding the investment strategies and related risks of
            exchange-traded funds (“ETFs”) can be found in the prospectus and
            statement of additional information (“SAI”) of each ETF. For more
            information about the ETFs, including copies of their prospectuses,
            SAIs, and annual reports, contact the ETFs. Information presented
            represents past performance. PAST PERFORMANCE IS NOT A GUARANTEE OR
            INDICATION OF FUTURE RESULTS. Investment return and principal value
            of an investment will fluctuate; so that an investor's shares, when
            redeemed, may be worth more or less than their original cost;
            current performance may be lower or higher than the performance data
            quoted. All funds are subject to market risk. No investment strategy
            or allocation can guarantee a profit or protect against a loss in a
            declining market. No investments are guaranteed or insured by the
            Federal Deposit Insurance Corporation. All of Staax’s clients are
            advised on a discretionary basis; Staax will select the investment
            based on information provided by the client. You confirm that you do
            not need, require or desire in-person or other personal investment
            advice. Our investment advice will be based on the personal
            information you provide to us through our website. We do not provide
            any investment advice through other means.
            <h1 class="text-xl pb-2  font-bold text-blackish">Fees</h1>Staax
            does not charge an account opening fee. Staax does not require a
            monthly minimum to maintain your account.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Payment Providers
            </h1>
            In order to use the payment functionality of our Site, you must open
            a "Dwolla Platform" account provided by Dwolla, Inc. and you must
            accept the Dwolla Terms of Service and Privacy Policy . Any funds
            held in the Dwolla account are held by Dwolla's financial
            institution partners as set out in the Dwolla Terms of Service. You
            must be 18 years of age or older to use the service. You authorize
            Staax to collect and share with Dwolla your personal information
            including full name, date of birth, social security number, physical
            address, email address and financial information, and you are
            responsible for the accuracy and completeness of that data. You
            understand that you will access and manage your Dwolla account
            through our Site, and Dwolla account notifications will be sent by
            Staax, not Dwolla. Staax will provide customer support for your
            Dwolla account activity, and can be reached at hello@staaxapp.com
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Order Execution
            </h1>
            Staax executes orders that are made as a request to another
            individual. By clicking request, you are giving Staax the right to
            execute an order when the requestee confirms that payment.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Referral Program
            </h1>
            Staax may, in Staax’s sole discretion, offer promotional referral
            programs (“Staax Referral Programs”) that allow you to obtain
            account credits or other rewards (“reward”) as a thank you to our
            existing customers (“referrers”) and to welcome our new customers
            (“referees”). Rewards may include products, services, or shares of
            stocks, which consist of a specified dollar value that can be
            claimed as a one-time investment that you control/manage. The
            following is applicable to all Staax Referral Programs, unless
            otherwise stated in the specific terms to a particular Staax
            Referral Program. To the extent any term in the specific terms
            conflict with these Terms, the specific terms to a particular Staax
            Referral Program shall control. 1. General Eligibility Requirements
            In addition to any eligibility requirements disclosed under the
            specific terms of a particular Staax Referral Program, the following
            requirements must be satisfied to receive any reward through an
            Staax Referral Program: a. Referrers must have Staax accounts that
            are in good standing and are responsible for sending no more than
            one invitation each to referees that you know personally, other than
            yourself, such as friends, family and known acquaintances using any
            unique referral link provided by Staax per Staax Referral Program;
            and b. Referees must complete and satisfy any account opening
            requirements using any designated Staax Referral Program or referral
            codes and must have Staax accounts in good standing to receive any
            reward. Staax is not responsible for incorrect entry or other
            failure on the part of referees when registering for an account.
            Referees must not have a preexisting Staax account prior to
            accepting an Staax Referral Program invitation. Referees may redeem
            no more than one referee reward but may earn future rewards as a
            referrer by satisfying the general eligibility requirements in this
            section. Staax reserves the right to determine, in our sole
            discretion, whether an account is closed and/or in good standing. If
            an account is closed or not in good standing for any reason at any
            time, any Staax Referral Program rewards for which the referrer or
            the referee would otherwise be eligible will be forfeited and any
            Staax Referral Program rewards already disbursed may be revoked. For
            either party to be eligible to earn a reward, both the referrer and
            the referee must meet these eligibility requirements. Rewards in the
            form of account credit may only be used to make a one-time
            investment to the account of the referrer. Rewards may expire at the
            discretion of Staax. Rewards cannot be shared or transferred to
            another account. 2. Compliance In connection with your participation
            in an Staax Referral Program, you agree to the following
            restrictions: a. No spam: You agree that you will not “spam” anyone
            with referrals to join Staax either by mass emailing, use of
            automated systems, bots, or automatic dialers, and you will not post
            Staax Referral Program links on websites or event or venue pages
            without the express consent from the owner or operator of any such
            websites or pages. You agree not to engage in or provide material
            support to any device or scheme that results in “spam” to referees.
            b. No misrepresentations: You agree that you will not attempt to
            mislead anyone in connection with the Staax Referral Programs. This
            includes, but is not limited to, creating fake accounts, profiles,
            links, or messages. c. No prohibited content: You agree that you
            will not use any defamatory, offensive, abusive, or obscene content
            in connection with the Staax Referral Programs. This includes, but
            is not limited to, content that violates someone else’s privacy and
            harassing content. d. No fraudulent activity: You agree not to
            defraud or abuse, or attempt to defraud or abuse, anyone in
            connection with the Staax Referral Programs. You agree to not engage
            in or provide material support to any activity that may be
            fraudulent, deceptive, or manipulative in connection with the Staax
            Referral Programs e. No misuse of Staax’s content: You agree not to
            sell Staax Referral Program links or create websites, email
            addresses, or social media with Staax’s name, images, or content
            without Staax’s express consent. Staax’s content is not intended for
            distribution to, or use by, any person or entity in any jurisdiction
            where such distribution or use would be contrary to law or
            regulation or which would subject Staax or any of Staax’s products
            or services to any authorization, registration, licensing or
            notification requirements within any jurisdictions or outside those
            jurisdictions in which Staax and its affiliates are licensed to
            operate. Staax reserves the right to delay delivery of a reward
            pending verification that a referrer or referee has complied with
            these terms and/or the terms of a specific Staax Referral Program.
            Violation of any of these restrictions may result in termination of
            your eligibility to participate in the Staax Referral Programs and
            may, in Staax’s sole discretion, deny you any Staax Referral Program
            rewards earned in violation or suspected violation of these
            restrictions. 3. Disclosure of Your Information By choosing to
            participate in an Staax Referral Program, you expressly acknowledge
            and accept that referrers and/or referees may learn that you
            (whether as a referrer or referee) have an Staax account. Other
            information disclosed to referrers and/or referees as part of the
            Staax Referral Programs may include name, Staax account status, and
            information about rewards received under the Staax Referral
            Programs. 4. Legal and Regulatory Requirements You represent and
            warrant to Staax that: (1) you are not subject to any statutory
            disqualification as set forth in sections 203(e) and 203(f) of the
            Investment Advisers Act of 1940 (the “Act”); (2) you are not
            currently the subject of any investigation or proceeding which could
            result in statutory disqualification under the Act; and (3) you do
            not have a “place of business” at which you regularly provide
            investment advisory services, solicit, meet with, or otherwise
            communicate with customers, or any other location that is held out
            to the general public as a location at which you provide investment
            advisory services, solicit, meet with, or otherwise communicate with
            customers in any U.S. state. If you cannot accurately make the
            preceding representations, you may not participate in any of the
            Staax Referral Programs. If you are currently participating in an
            Staax Referral Program and the preceding representations become
            inaccurate, you may no longer be eligible to participate in the
            Staax Referral Programs. The Staax Referral Programs are void where
            prohibited by law. You agree to release, discharge, and hold Staax
            and its respective directors, officers, employees, promotion
            agencies, and assign harmless against any and all claims, damages,
            and liability caused, or claimed to be caused, by participation in
            any Staax Referral Program or use or redemption of any rewards.
            Staax assumes no responsibility for invitations sent by referrers,
            and Staax assumes no responsibility for undeliverable email or text
            messages to referees. For referrers, by sending the invitation via
            text message, you represent that you have consent to send such text
            messages to each referee. Rewards that you receive for participating
            in a Staax Referral Program may be taxable, so please consult your
            tax advisor if you have any questions about the tax implications to
            your personal situation. Staax may offer compensation to current
            clients, affiliate marketers, solicitors, and other strategic
            partners who recommend Staax and refer new clients. The compensation
            received for referring a new client will depend on the relationship
            with the aforementioned client, affiliate marketer, solicitor, or
            other strategic partner and the volume and/or quality of new clients
            they are referring to Staax. New clients are advised of such
            compensation prior to opening an account. Staax supervises the
            referral activities of current customers, affiliate marketers
            (including “bloggers”), solicitors, and other strategic partners.
            Clients are not charged a fee nor do they incur any additional costs
            for being referred to Staax by a current client, affiliate marketer,
            solicitor, or other strategic partner. 5. Changes to Staax Referral
            Programs and Program Terms Staax reserves the right to suspend,
            cancel, terminate, limit, cap, or modify any Staax Referral Program
            and/or reward at any time at its sole discretion and without prior
            notice. Please check these Terms regularly to confirm whether any
            changes have been made as indicated by the “Last Updated” date
            posted at the top of this page. Your continued participation in any
            Staax Referral Program after we make any changes to these Terms will
            demonstrate your acceptance of any such changes. If you do not
            accept any such changes, your sole and exclusive remedy is to cease
            participation in the Staax Referral Programs. 6. Other Staax
            Referral Program Information Investing involves risk, including loss
            of principal. The information provided in this section should not be
            considered a solicitation to buy, an offer to sell, or a
            recommendation for any security in any jurisdiction where such an
            offer, solicitation, or recommendation would be unlawful or
            unauthorized. Any preliminary information provided to prospective
            customers prior to Staax agreeing to enter into an advisory
            relationship does not constitute investment advice and should not be
            relied on as such. The principal contributed to an account and any
            earnings thereon may lose value and are not FDIC insured, bank
            guaranteed, insured by any federal government agency, or guaranteed
            by Staax. Your investment advisory agreement with Staax governs our
            investment advisory relationship with you, and these Terms do not in
            any way amend, supersede, change, or replace your investment
            advisory agreement. Staax reserves the right to limit or cap the
            amount of Staax Referral Program rewards you are eligible to receive
            and to refuse to cover any Staax Referral Program rewards if Staax
            determines that such rewards were obtained under wrongful or
            fraudulent circumstances, were awarded to you in error, that
            inaccurate or incomplete information was provided in opening an
            account, or that any of these Terms have been violated. By
            participating in the Staax Referral Programs, you undertake to
            perform your duties under these Terms in a manner consistent with
            these Terms, any and all of Staax’s instructions to you, the
            provisions of the Act, and the SEC’s rules under the Act.
            <h1 class="text-xl pb-2  font-bold text-blackish">Your license</h1>
            Subject to these Terms, Staax grants you a personal, non-exclusive,
            non-transferable, limited and revocable license to access the Site
            for your personal use (“Your License”). Staax reserves the right to
            revoke Your License to use the Staax Site at any time, including if
            we detect any unauthorized use by you in violation of these Terms.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Intellectual Property
            </h1>
            The Site, the content, any materials downloaded, and all
            intellectual property pertaining to or contained on the Site
            (including but not limited to copyrights, patents, database rights,
            graphics, designs, text, logos, trade dress, trademarks, and service
            marks) are owned by Staax or third parties; all rights, title, and
            interest will remain the property of Staax and/or such third-party
            owner, as applicable. All content is protected by trade dress,
            copyright, patent, and trademark laws, as well as various other
            intellectual property and unfair competition laws. You are
            authorized to view and retain a copy of pages of the Site only for
            your own personal, non-commercial use. You may also view and make
            copies of relevant documents, pages, images, or other materials on
            the Site for the purpose of transacting business with Staax. You
            agree that you will not duplicate, publish, modify, create
            derivative works from, participate in the transfer of, post on the
            World Wide Web, or in any way distribute or exploit the Site, or any
            portion of the Site, for any public or commercial use, without the
            express written consent of Staax. Additionally, you agree that you
            (i) will not remove or alter any author, trademark, other
            proprietary notice, or legend displayed on the Site (or printed
            pages produced from the Site), and (ii) will not make any other
            modifications to any documents obtained from the Site other than in
            connection with completing information required to transact business
            with Staax.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Use of Information and Materials
            </h1>
            The information and materials contained on the Site, and the Terms,
            policies, and descriptions on the Site, are subject to change. You
            accept sole responsibility for all of your activities using the
            Site. Your use of the Site is limited to the intended function of
            the Site. Unauthorized use of the Site and systems, including but
            not limited to unauthorized entry into Staax’s systems or misuse of
            any information posted on the Site, is strictly prohibited. You may
            not use the Site in a manner that: a. harasses, abuses, stalks,
            threatens, defames, or otherwise infringes or violates the rights of
            any party (including but not limited to rights of publicity or other
            proprietary rights); b. is unlawful, fraudulent, or deceptive; c.
            uses technology or other means to access content or systems of Staax
            in a manner that is not authorized by Staax; d. uses or launches any
            automated system, including without limitation, "robots," "spiders,"
            or "offline readers," to access content or systems of Staax; e.
            attempts to introduce viruses or any other computer code, files, or
            programs that interrupt, destroy, or limit the functionality of any
            computer software, hardware, or telecommunications equipment; f.
            attempts to gain unauthorized access to Staax’s computer network or
            user accounts; g. encourages conduct that would constitute a
            criminal offense or that gives rise to civil liability; h. violates
            the Terms or any other Staax policies; i. attempts to damage,
            disable, overburden, or impair Staax servers or networks; j. fails
            to comply with applicable third-party terms; or k. constitutes any
            other inappropriate conduct, as determined by us in our sole
            discretion.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              System Outages, Slowdowns, and Capacity Limitations
            </h1>
            At times you may experience difficulty accessing the Site or
            communicating with Staax through the Internet, or other electronic
            wireless services, as a result of high Internet traffic,
            transmission problems, systems capacity limitations, or other
            problems. Any computer system or other electronic device, whether it
            is yours, an Internet service provider's, or Staax’s, can experience
            unanticipated outages or slowdowns or have capacity limitations.
            Staax is not responsible for failure or delay of performance caused
            by such problems.
            <h1 class="text-xl pb-2  font-bold text-blackish">Security</h1>Staax
            makes no warranties whatsoever to you, express or implied, regarding
            the security of the Site, including with respect to the ability of
            unauthorized persons to intercept or access information transmitted
            by you through the Site. You acknowledge and agree that you are
            solely responsible for maintaining the security of your devices.
            Staax is not responsible for any losses resulting from the loss or
            theft of your device, the loss or theft of your information
            transmitted from or stored on your devices, or any losses arising
            from the use of your device by someone whom you have given access.
            You are required to notify us of any unauthorized use of the Site by
            using the contact information below. To protect the security of your
            information, Staax may require you to authenticate your identity
            (i.e., prove that you are who you say you are) when you use the
            Site.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Severability/No Waiver
            </h1>
            If any provision of the Terms shall be deemed unlawful, void, or
            unenforceable for any reason, then such provision shall be deemed
            severable from the Terms and shall not affect the validity and
            enforceability of any remaining provisions. Staax’s failure to
            enforce the strict performance of any provision of the Terms or the
            additional terms and conditions for any product or service will not
            constitute a waiver of our right to subsequently enforce such
            provision or any other provisions of the Terms or the additional
            terms and conditions.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Limitation of Liability
            </h1>
            UNDER NO CIRCUMSTANCES SHALL WE BE LIABLE FOR ANY DIRECT OR
            INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES THAT MAY
            ARISE IN CONNECTION WITH THIS SITE, OR FROM YOUR USE OF, OR
            INABILITY TO USE THIS SITE; OR IN CONNECTION WITH ANY FAILURE OF
            PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY IN
            OPERATION OR TRANSMISSION, COMPUTER VIRUS, OR LINE OR SYSTEM FAILURE
            EVEN IF WE ARE ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, LOSSES,
            OR EXPENSES. Some jurisdictions do not allow the exclusion or
            limitation of liability for consequential or incidental damages. In
            such jurisdictions, our liability is limited to the greatest extent
            permitted by law.
            <h1 class="text-xl pb-2  font-bold text-blackish">Disclaimers</h1>
            THE SITE IS PROVIDED TO YOU ON AN “AS-IS” AND “AS-AVAILABLE” BASIS
            WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND, WHETHER
            EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED
            WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
            NON-INFRINGEMENT. STAAX MAKES NO WARRANTY THAT (i) THE SITE WILL
            MEET YOUR REQUIREMENTS, (ii) THAT OPERATION OF THE SITE WILL BE
            UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, OR (iii) THE CONTENT
            THAT MAY BE OBTAINED FROM THE USE OF THE SITE WILL BE ACCURATE OR
            RELIABLE. STAAX UNDERTAKES NO OBLIGATION TO UPDATE THE SITE. NO
            ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM
            STAAX, OR THROUGH THE SITE SHALL CREATE ANY WARRANTY REGARDING THE
            SITE NOT EXPRESSLY STATED IN THIS AGREEMENT. YOU UNDERSTAND AND
            ACKNOWLEDGE THAT ADDITIONAL DISCLAIMERS, LIMITATIONS, AND NOTICES
            REGARDING THE SITE AND ITS CONTENT AND DATA MAY BE PROVIDED BY STAAX
            FROM TIME TO TIME WITHIN THE SITE. WE DO NOT WARRANT THAT THE SITE
            WILL OPERATE ERROR-FREE OR THAT THE SITE AND ITS SERVERS ARE FREE OF
            COMPUTER VIRUSES OR OTHER HARMFUL CONTENT. IF YOUR USE OF THE SITE,
            THE MATERIAL, OR THE SOFTWARE RESULTS IN THE NEED FOR SERVICING OR
            REPLACING EQUIPMENT OR DATA, WE ARE NOT RESPONSIBLE FOR THOSE COSTS.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Indemnification
            </h1>
            You will defend, indemnify, and hold us harmless from and against
            any actual or threatened suit, actions, proceedings (at law or in
            equity), claims, damages, payments, deficiencies, fines, judgments,
            settlements, liabilities, losses, costs, and expenses (including
            reasonable attorneys' fees, costs, penalties, interest, and
            disbursements) arising from or related to your conduct with respect
            to the Site or violation (or alleged violation) of these Terms.
            Under no circumstance will Staax be liable for damages of any kind
            that result from your use of, or the inability to use, the Site.
            <h1 class="text-xl pb-2  font-bold text-blackish">Termination</h1>We
            may terminate or suspend your access to the Site, delete your
            profile and any content or information that you have posted on the
            Site and/or prohibit you from using or accessing the Site (or any
            portion, aspect or feature of the Site) if you violate the Terms,
            effective immediately, which may result in the forfeiture and
            destruction of all information associated with you and your
            activities in connection with the Site. If you wish to terminate
            your account, you may do so by following the instructions on the
            Site. Any fees paid hereunder are non-refundable. In the event of
            termination, you will still be bound by your obligations under these
            Term. Changes You are responsible for reviewing these Terms
            regularly. Staax reserves the right, at any time, without notice to
            you, and in its sole discretion, to modify or discontinue the Site,
            the Terms, or any Staax policies related to use of the Site.
            Revisions to the Terms or Staax’s policies may be provided through
            the Site, including by posting the revisions on the Site. Such
            revisions will go into immediate effect once posted to the Site.
            Continued use of the Site following such modifications to the Site,
            the Terms, additional terms and conditions for any service, or other
            Staax policies will constitute your acceptance of such modifications
            and revisions.
            <h1 class="text-xl pb-2  font-bold text-blackish">
              Influencer Content
            </h1>
            All content generated by individuals, including users of the Site,
            providing media advertising, marketing, promotion, endorsement, and
            other influencer services (“Influencers”) for Staax Services must
            comply with all legal and Staax requirements. For example: (1) any
            material connection between the Influencer and Staax must be clearly
            and conspicuously disclosed in the Influencer’s content, including
            all posts, blogs, publications, testimonials, and endorsements, such
            as whether the Influencer is receiving any payments, Staax Referral
            Program rewards, or free products or Services from Staax (e.g.,
            #Paid, #Ad, #Sponsored); and (2) Influencers must not make any false
            or misleading statements about Staax’s Services, and (3) Make any
            disclosures be in the same language as the Influencer’s content.
            Influencer content may constitute endorsements (i.e., any
            advertising message—including verbal statements, demonstrations, or
            depictions of the name, signature, likeness, or other identifying
            characteristics of Staax) that must reflect the honest opinions,
            findings, beliefs, or experiences of the endorser. Endorsements may
            not be presented out of context or reworded so as to distort in any
            way the Influencer’s opinion or experience with Staax’s Services.
            When endorsing Staax’s Services, the Influencer must have actually
            used the Services.
            <h1 class="text-xl pb-2  font-bold text-blackish">Contact Us</h1>If
            you have any questions regarding the Site, these should be directed
            to: Email: hello@staaxapp.com
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Legal;
