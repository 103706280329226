import phoneMock from "../../assets/phone-mocks/header-phone.png";
import "./Header.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ScrollWord from "../ScrollWord/ScrollWord";
import Join from "../Join/Join";

import "animate.css/animate.min.css";
const Header = () => {
  return (
    <div className="header-container ">
      <Row>
        <Col lg={6}>
          {/* <div
            style={{
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <h1 className="header-header">Get paid in</h1>
            <SlideWord />
          </div> */}
          <div>
            <ScrollWord
              title={"GET PAID IN"}
              wordsInput={["TSLA", "AAPL", "AMZN", "GOOGL", "TSLA"]}
            />
            <ScrollWord
              title={"FOR"}
              wordsInput={[
                "GROCERIES",
                "BILLS",
                "THE GYM",
                "HAPPY HOUR",
                "GROCERIES",
              ]}
            />
          </div>

          <h2 className="header-header-2">
            We make investing as easy as receiving payments from your friends.
          </h2>
          <Join />
        </Col>
        {/* className="animate__animated animate__slideInDown" */}
        <Col style={{ overflow: "hidden" }}>
          <div className="header-img-container">
            <img src={phoneMock} alt="phones" className="header-img floating" />
          </div>
        </Col>

        {/* <Col lg={6}>
          <img src={phoneMock} alt="phones" className="header-img " />
        </Col> */}
        {/* <button className="header-btn no-bs-border">Join the Waitlist</button> */}
      </Row>
    </div>
  );
};

export default Header;
