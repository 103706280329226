import React, { useState } from "react";
import "./Nav.css";
import "./hamburgers.css";
import logo from "../../assets/staax-orange.png";
import { useLocation } from "react-router-dom";

const Nav = ({ homeRef, featuresRef, faqRef }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  console.log("location.pathname", location.pathname);

  const scrollHome = () => {
    homeRef.current.scrollIntoView();
    toggleMenu();
  };
  const scrollFeatures = () => {
    featuresRef.current.scrollIntoView();
    toggleMenu();
  };
  const scrollFaq = () => {
    faqRef.current.scrollIntoView();
    toggleMenu();
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const responsive = menuOpen ? " responsive" : "";

  const hamburgerType = menuOpen ? " is-active" : "";

  // const pageURI = window.location.pathname + window.location.search;

  return (
    <React.Fragment>
      <div className={"topnav" + responsive} id="myTopnav">
        <a
          href="https://wefunder.com/staax"
          target="_blank"
          rel="noreferrer"
          className="nav-top-banner"
        >
          Invest in Staax on <span className="nav-underline">WeFunder</span> for
          as little as $100!
        </a>
        <div className="bottom-strip">
          <div className="flex-item">
            <a href="/" className="top-nav-brand topnav-first">
              <img className="topnav-logo" src={logo} alt="staax" />
            </a>
          </div>

          <div className="nav-link-container flex-item">
            <div className="nav-link" onClick={scrollHome}>
              Home
            </div>

            <div className="nav-link" onClick={scrollFeatures}>
              Features
            </div>
            <div className="nav-link" onClick={scrollFaq}>
              FAQ
            </div>
          </div>

          <div className="flex-item temp-div"></div>

          <button
            className={
              " hamburger hamburger--spin no-bs-border hamburger-icon" +
              hamburgerType
            }
            type="button"
            onClick={toggleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Nav;
