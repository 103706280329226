export const Data = [
  {
    question: "Do I need to own stock to send it to someone?",
    answer: "No you don't! Send money directly from your bank account",
  },

  {
    question: "What do I need to get started?",
    answer:
      "All you need is a phone number to receive a fractional share. To claim a stock to your portfolio, you’ll need to sign up with Staax.",
  },

  {
    question: "What types of stocks can I choose from?",
    answer: "Individual stocks, ETF and any other securities",
  },

  {
    question: "Is there an option to send and receive cryptocurrency?",
    answer:
      "We are working on launching this feature which will be incorporated in the next phase",
  },
  {
    question: "What if I don't want to receive stocks?",
    answer: "You have the option to receive cash instead of stocks",
  },
  {
    question: "What if I need to liquidate my stocks?",
    answer:
      "You can easily sell your shares and transfer the money from staax to your bank account",
  },
  {
    question: "What if I do not want my friends to see my transactions?",
    answer:
      "All portfolio amounts will not be visible. You get to choose which transactions you share with your friends or which ones you want private.",
  },

  {
    question: "What if someone sends me stocks I don’t want?",
    answer:
      "You can only receive stocks from your top 5 that you have marked as your favorites on your profile dashboard. If you change your mind at the time of receipt, you can swap out for cash!",
  },
  {
    question: "What if my stock portfolio goes down in value?",
    answer:
      "With every investment there is risk, but we encourage long term investing where we have seen steady upward trends.",
  },
  {
    question:
      "I’m new to investing. How do I find the latest information on stocks?",
    answer:
      "We have an explore page where you can search by different themes and industries. When you click into a security, you will have access to all the financial data as well as the latest news associated with the company.",
  },

  {
    question: "I am concerned about privacy, will others see my portfolio?",
    answer:
      "Others will only see your 5 favorite stocks and transactions you make public, not your portfolio or your balances.",
  },
  {
    question: "Can I connect my existing brokerage accounts?",
    answer:
      "Not currently. When you sign up with Staax, you're creating a new brokerage account with us via our partner Alpaca. They help us by executing the trades, issuing tax forms, and much more.",
  },
  {
    question: "Is Staax free?",
    answer:
      "Yes! We never charge fees to the user when transacting or trading.",
  },
  {
    question: "Does sending stock trigger a tax event?",
    answer:
      "Not on Staax. We've crafted a way to send and receive your favorite stocks without any tax implications using a cash transfer mechanism. This way, your portfolio stays intact and grows over time! However, standard taxes will apply if you sell your stock, and we will issue you a Form 1099 at year-end.",
  },
];
