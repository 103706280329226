// import phones from "../../assets/phone-mock.png";
import "./HowItWorks.css";
// import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// import { ReactComponent as Img1 } from "../../assets/howitworks/1.svg";
// import { ReactComponent as Img2 } from "../../assets/howitworks/2.svg";
// import { ReactComponent as Img3 } from "../../assets/howitworks/3.svg";

import img1 from "../../assets/icons/debit-card.png";
import img2 from "../../assets/icons/stock-market.png";
import img3 from "../../assets/icons/pie-chart.png";
import img4 from "../../assets/icons/money-graph-with-up-arrow.png";

import { AnimationOnScroll } from "react-animation-on-scroll";
// import phoneCardLottie from "../../assets/lotties/phone-card-lottie.json";
// import phoneStockLottie from "../../assets/lotties/stock-app.json";
// import fractionalLottie from "../../assets/lotties/fractional.json";
// import gainsLottie from "../../assets/lotties/gains.json";

// import Lottie from "lottie-react";

import "animate.css/animate.min.css";
import { forwardRef } from "react";

const HowItWorks = forwardRef((props, ref) => {
  return (
    <div className="howitworks-container" ref={ref}>
      <h2 className="howitworks-header">How It Works</h2>
      <Row xxl={4}>
        <Col>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <div className="howitworks-section ">
              <img src={img1} alt="step1" className="howitworks-section-img" />
              {/* <Lottie
                animationData={phoneCardLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">Step 1</h3>
              <p className="step-description">
                Create an account and link up your debit card or bank account.
              </p>
            </div>
          </AnimationOnScroll>
        </Col>
        <Col>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <div className="howitworks-section ">
              <img src={img2} alt="step2" className="howitworks-section-img" />
              {/* <img src={img2} alt="step1" className="howitworks-section-img" /> */}
              {/* <Lottie
                animationData={phoneStockLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">Step 2</h3>
              <p className="step-description">
                Pick your top 5 favorite stocks or ETFs.
              </p>
            </div>
          </AnimationOnScroll>
        </Col>
        <Col>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <div className="howitworks-section ">
              <img src={img3} alt="step3" className="howitworks-section-img" />
              {/* <img src={img3} alt="step1" className="howitworks-section-img" /> */}
              {/* <Lottie
                animationData={fractionalLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">Step 3</h3>
              <p className="step-description">
                Receive fractional shares of stock for everyday reimbursements.
              </p>
            </div>
          </AnimationOnScroll>
        </Col>
        <Col>
          <AnimationOnScroll animateIn="animate__slideInLeft">
            <div className="howitworks-section ">
              <img src={img4} alt="step4" className="howitworks-section-img" />
              {/* <Lottie
                animationData={gainsLottie}
                loop={true}
                autoplay={true}
                size={10}
              /> */}
              <h3 className="step-number">Step 4</h3>
              <p className="step-description">Enjoy the perks of investing.</p>
            </div>
          </AnimationOnScroll>
        </Col>
      </Row>
    </div>
  );
});

export default HowItWorks;
