// import phones from "../../assets/phone-mock.png";
import "./Join.css";
import Row from "react-bootstrap/Row";
import React, { useState, useMemo } from "react";
import * as api from "../../api/index";
import Lottie from "lottie-react";
import loadingLottie from "../../assets/lotties/loading-wheel-lottie.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faApple, faAndroid } from "@fortawesome/free-brands-svg-icons";

// eslint-disable-next-line no-useless-escape
const emailRegexPattern = /^\w+([\.(\-\+)]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;

const Join = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const validEmail = useMemo(() => emailRegexPattern.test(email), [email]);

  const [showWaitlistForm, setShowWaitlistForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleNameChange = (newName) => {
    setNameError("");
    setName(newName);
  };

  const handleEmailChange = (newEmail) => {
    //TODO: add regex for email validation
    setEmailError("");
    setEmail(newEmail);
  };

  const handlePhoneNumberChange = (newPhoneNumber) => {
    setPhoneError("");
    let tempNewPhoneNumber = newPhoneNumber;
    // let tempPhoneNumber = phoneNumber;
    let newNumLength = tempNewPhoneNumber.replace(/[^0-9]/g, "").length;
    // let currNumLength = tempPhoneNumber.replace(/[^0-9]/g, "").length;

    // eslint-disable-next-line
    let regExAlphabet = /[a-zA-Z]/g;
    // eslint-disable-next-line
    let regExSpecial = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g;

    tempNewPhoneNumber = tempNewPhoneNumber.replace("(", "");
    tempNewPhoneNumber = tempNewPhoneNumber.replace(") ", "");
    tempNewPhoneNumber = tempNewPhoneNumber.replace("-", "");

    if (
      regExAlphabet.test(tempNewPhoneNumber) ||
      regExSpecial.test(tempNewPhoneNumber) ||
      newNumLength > 10
    ) {
      return;
    }
    // setShowFailAlert(false);

    if (newNumLength >= 3 && !newPhoneNumber.includes("(")) {
      newPhoneNumber =
        "(" + newPhoneNumber.slice(0, 3) + ") " + newPhoneNumber.slice(3);
    }

    if (
      newPhoneNumber[newPhoneNumber.length - 1] === ")" &&
      newPhoneNumber.length < phoneNumber.length
    ) {
      newPhoneNumber = newPhoneNumber.substring(1, newPhoneNumber.length - 2);
    }

    if (newNumLength >= 7 && !newPhoneNumber.includes("-")) {
      newPhoneNumber =
        newPhoneNumber.slice(0, 9) + "-" + newPhoneNumber.slice(9);
    }

    if (newPhoneNumber[newPhoneNumber.length - 1] === "-") {
      newPhoneNumber = newPhoneNumber.substring(0, newPhoneNumber.length - 1);
    }
    setPhoneNumber(newPhoneNumber);
  };

  const handleJoinAndroidWaitlist = () => {
    setShowWaitlistForm(!showWaitlistForm);
  };

  const handleSubmit = async () => {
    console.log("handle submit");

    if (name === "") {
      setNameError("Please Enter Your Name");
      return;
    }
    if (phoneNumber.length < 14) {
      setPhoneError("Please Enter a Valid Phone Number");
      return;
    }
    if (!validEmail) {
      setEmailError("Please Enter a Valid Email Address");
      return;
    }

    setLoading(true);

    try {
      const response = await api.postSubmission(name, phoneNumber, email);
      console.log(response);
      setEmail("");
      setPhoneNumber("");
      setName("");
      setSuccessMessage("You're on the Waitlist!");
    } catch (err) {
      console.log("ERR", err);
      // setEmailError(err.response.data.message);
      setEmailError("Email Address is already on the waitlist.");
    }

    setLoading(false);
  };

  return (
    <div className="join-container">
      {!showWaitlistForm ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: 250,
          }}
        >
          <a
            className="download-btn"
            href="https://apps.apple.com/us/app/staax/id1635862905"
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faApple} size="2x" />
            <span style={{ marginLeft: 10 }}>Download for iPhone</span>
          </a>
          <div className="download-btn" onClick={handleJoinAndroidWaitlist}>
            <FontAwesomeIcon icon={faAndroid} size="2x" />
            <span style={{ marginLeft: 10 }}>Join Android Waitlist</span>
          </div>
        </div>
      ) : (
        <div style={{ marginTop: 20 }}>
          <form>
            <Row>
              <h2>Join the Android Waitlist</h2>
            </Row>
            <Row>
              <input
                type="text"
                name="name"
                placeholder="Name"
                className="join-input no-bs-border"
                value={name}
                onChange={(e) => handleNameChange(e.target.value)}
              />
              {nameError !== "" && <p style={{ color: "red" }}>{nameError}</p>}
            </Row>
            <Row>
              {/* <input
     type="tel"
     id="phone"
     name="phone"
     pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
     placeholder="Phone Number"
     className="join-input no-bs-border"
   /> */}
              <input
                type="text"
                className="join-input no-bs-border"
                placeholder="Phone Number"
                inputProps={{ inputMode: "numeric" }}
                pattern="[0-9]*"
                value={phoneNumber}
                onChange={(e) => handlePhoneNumberChange(e.target.value)}
              />
              {phoneError !== "" && (
                <p style={{ color: "red" }}>{phoneError}</p>
              )}
            </Row>
            <Row>
              <input
                type="text"
                name="email"
                placeholder="Email Address"
                className="join-input no-bs-border"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
              />
              {emailError !== "" && (
                <p style={{ color: "red" }}>{emailError}</p>
              )}
              {successMessage !== "" && (
                <p style={{ color: "green" }}>{successMessage}</p>
              )}
              {loading && (
                <Lottie
                  animationData={loadingLottie}
                  loop={true}
                  autoPlay={true}
                  style={{ height: 100 }}
                />
              )}
            </Row>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-around",
                }}
              >
                <input
                  type="button"
                  value="Cancel"
                  className="cancel-btn"
                  onClick={handleJoinAndroidWaitlist}
                />
                <input
                  type="button"
                  value="Join Waitlist"
                  className="join-btn no-bs-border"
                  onClick={handleSubmit}
                />
              </div>
              {/* <button className="join-btn no-bs-border" onClick={handleSubmit}>
     Join the Waitlist
   </button> */}
            </Row>
          </form>
        </div>
      )}
    </div>
  );
};

export default Join;
