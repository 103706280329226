import axios from "axios";
const URL = "https://44i5kzhc43.execute-api.us-west-2.amazonaws.com/dev/submit";

export const postSubmission = async (name, phone, email) => {
  const apiResponse = await axios.post(URL, {
    name: name,
    phone: phone,
    email: email,
  });
  console.log("api resp", apiResponse.data);
  return apiResponse.data;
};
