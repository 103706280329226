// import React from "react";
// import "bootstrap/dist/css/bootstrap.css";
import "./Footer.css";
// import crunchBaseLogo from "../../resources/logos/crunchbase-logo.png";
import logo from "../../assets/staax-orange.png";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCoffee, faTikTo } from "@fortawesome/free-solid-svg-icons";
import {
  faTiktok,
  faLinkedin,
  faInstagram,
  faTwitter,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    // <React.Fragment>
    <div className="footer-container">
      <img
        alt="nav-logo"
        src={logo}
        className="nav-logo"
        style={{ marginRight: 15, width: 200 }}
      />

      {/* <p className="navbar-brand">Staax</p> */}

      <p>
        {/* <a href="tel:702-403-6473" className="footer-link">
            (702) 403-6473
          </a> */}
        <br></br>
        <a href="mailto:hello@staaxapp.com" className="footer-link">
          hello@staaxapp.com
        </a>
      </p>

      <div className="text-center fa-size">
        {/* the following {} should be kept due to achor tags requiring content */}
        <a
          href="https://www.linkedin.com/company/staax/"
          className="social"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon color={"#FF7700"} icon={faLinkedin} />
        </a>
        <a
          href="https://www.instagram.com/staax.app/?hl=en"
          className="social"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon color={"#FF7700"} icon={faInstagram} />
        </a>
        <a
          href="https://www.tiktok.com/@staax.app/"
          className="social"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon color={"#FF7700"} icon={faTiktok} />
        </a>

        <a
          href="https://twitter.com/StaaxApp/"
          className="social"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon color={"#FF7700"} icon={faTwitter} />
        </a>
        <a
          href="https://medium.com/@staax/"
          className="social"
          target={"_blank"}
          rel="noreferrer"
        >
          <FontAwesomeIcon color={"#FF7700"} icon={faMedium} />
        </a>
      </div>
      <p>
        {/* <a href="/legal" className="footer-link">
          Legal
        </a> */}
        <Link to="/legal" className="footer-link">
          Legal
        </Link>
        <br></br>
        {/* <a href="/privacy" className="footer-link">
          Privacy Policy
        </a> */}
        <Link to="/privacy" className="footer-link">
          Privacy Policy
        </Link>
      </p>

      <p className="m-0 text-center" style={{ color: "#9E9E9E" }}>
        Copyright &copy; 2022 Staax, Inc.
      </p>
    </div>
  );
};

export default Footer;
