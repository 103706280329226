import { Outlet } from "react-router-dom";
import SwiperCore, { Autoplay } from "swiper";

const App = () => {
  SwiperCore.use([Autoplay]);

  return (
    <div style={{ backgroundColor: "#121212", minHeight: "100vh" }}>
      <Outlet />
    </div>
  );
};

export default App;
