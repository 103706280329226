import React from "react";

import "./Carousel.css";

// import phone1 from "../../assets/mocks/congrats.png";
// import phone2 from "../../assets/mocks/review.png";
// import phone3 from "../../assets/mocks/pay.png";
// import phone4 from "../../assets/mocks/feed.png";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";
// import { AnimationOnScroll } from "react-animation-on-scroll";

const CarouselPage5 = ({ title, description, image, customClass = "" }) => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 600;
  // const pageStyle = isMobile ? {} : { height: "60vh" };
  return (
    <React.Fragment>
      <Row
        className={`page-row-style ${customClass}`}
        style={{
          justifyContent: "center",
          alignItems: "center",
          overflow: "hidden",
        }}
      >
        <div className="page-row-bg">
          <Col
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: "10%",
            }}
          >
            <h2 className="cpage-header">{title}</h2>
            <p className="cpage-description">{description}</p>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <div
              style={{
                borderRadius: 10,
              }}
            >
              <img
                src={image}
                alt={"phone1"}
                style={
                  isMobile
                    ? {
                        height: "70vh",
                        width: "auto",
                        margin: "auto",
                        // left: "",
                      }
                    : {
                        height: "90vh",
                        width: "auto",
                        maxWidth: "100%",
                        margin: "auto",
                        // marginLeft: "15vw",
                      }
                }
                className="features-img floating"
              />
            </div>
          </Col>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default CarouselPage5;
