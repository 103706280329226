import "./BannerButton.css";
import "animate.css/animate.min.css";
const BannerButton = ({ buttonText, link }) => {
  return (
    <a
      style={{ textDecoration: "none" }}
      href={link}
      target="_blank"
      rel="noreferrer"
    >
      <div className="top-banner-btn top-banner-container ">
        <span className="top-banner-text">{buttonText}</span>
      </div>
    </a>
  );
};

export default BannerButton;
