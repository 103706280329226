// import "../App.js";
import { Data } from "./Data.js";
import React, { forwardRef, useState } from "react";
// import { IconContext } from "react-icons";
// import { FiPlus, FiMinus } from "react-icons/fi";

import "./FaqSection.css";

const FaqSection = forwardRef((props, ref) => {
  const [clicked, setClicked] = useState(false);

  const toggle = (index) => {
    if (clicked === index) {
      return setClicked(null);
    }

    setClicked(index);
  };

  return (
    <React.Fragment>
      <div style={{ position: "relative", bottom: "50px" }} ref={ref}></div>
      <div className="accordion-section">
        <div className="container">
          <h1 className="faq-title">FAQ</h1>
          {Data.map((item, index) => {
            return (
              <div className="faq-qa">
                <div className="wrap" onClick={() => toggle(index)} key={index}>
                  <p className="faq-question">{item.question}</p>

                  {clicked === index ? (
                    <span className="faq-span-larger">-</span>
                  ) : (
                    <span className="faq-span">+</span>
                  )}
                </div>
                {clicked === index ? (
                  <div className="dropdown">
                    <p className="p1">{item.answer}</p>
                  </div>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
});

export default FaqSection;
