import React from "react";

import "./Carousel.css";

import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

const CarouselPage = ({ title, description, image, customClass = "" }) => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 600;
  return (
    <React.Fragment>
      <Row
        className={`page-row-style ${customClass}`}
        style={{ justifyContent: "center", alignItems: "center" }}
      >
        <div className="page-row-bg">
          <Col
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              // marginTop: "10%",
            }}
          >
            <h2 className="cpage-header">{title}</h2>
            <p className="cpage-description">{description}</p>
          </Col>
          <Col style={{ alignItems: "center" }}>
            <div
              style={{
                borderRadius: 10,
              }}
            >
              <img
                src={image}
                alt={"phone1"}
                style={
                  isMobile
                    ? {
                        height: "50vh",
                        width: "auto",
                        margin: "auto",
                        paddingBottom: 50,
                      }
                    : {
                        width: "auto",
                        // position: "absolute",
                        // top: 0,
                        // right: "15vw",
                        // right: "10vw",
                        // width: "80%", maxHeight: "50vh",
                        height: "80vh",
                        // maxWidth: "50vw",
                        zIndex: 3,
                        margin: "auto",
                        paddingBottom: 100,
                        // marginBottom: "70vh",
                        // marginLeft: "10vw",
                      }
                }
                className="features-img floating"
              />
            </div>
          </Col>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default CarouselPage;
