import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper";

import "./Carousel.css";
import CarouselPage from "./CarouselPage";

import social1 from "../../assets/phone-mocks/social-investing.png";
import fractional from "../../assets/phone-mocks/fractional.png";
import instant from "../../assets/phone-mocks/instant.png";
import financeFinger from "../../assets/phone-mocks/finance-finger.png";
import changeMind from "../../assets/phone-mocks/change-mind.png";
import { forwardRef } from "react";
import CarouselPage5 from "./CarouselPage5";

const Carousel = forwardRef((props, ref) => {
  const [animationStyle, setAnimationStyle] = useState("");

  const handleSlideChange = () => {
    setAnimationStyle(animationStyle ? "" : "animate__slideInLeft");
  };
  return (
    <React.Fragment>
      <div
        style={{ position: "relative", bottom: "100px", zIndex: 0 }}
        ref={ref}
      ></div>
      {/* <div className="swiperWrapper"> */}
      <Swiper
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        autoplay={{ delay: 3000 }}
        modules={[Pagination]}
        className={"mySwiper"}
        onSlideChange={() => handleSlideChange}
      >
        <SwiperSlide>
          <CarouselPage
            title={"Social Investing, Made Fun"}
            description={
              "See what stocks your friends are sending and receiving through the social feed."
            }
            image={social1}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselPage5
            title={"Fractional Shares"}
            description={
              "You don’t need large sums of money to invest in your favorite companies, send and receive for as little as $1."
            }
            image={fractional}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselPage5
            title={"Instant and Hassle-Free"}
            description={"No fees or paperwork, ever."}
            image={instant}
            customClass={animationStyle}
          />
        </SwiperSlide>

        <SwiperSlide>
          <CarouselPage
            title={"Change Your Mind?"}
            description={
              "You can choose not to accept payment as stock, instead you can accept as cash."
            }
            image={changeMind}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CarouselPage
            title={"Finance at your Fingertips"}
            description={
              "Access real time information and insights about your portfolio."
            }
            image={financeFinger}
          />
        </SwiperSlide>
      </Swiper>
      {/* </div> */}
    </React.Fragment>
  );
});

export default Carousel;
