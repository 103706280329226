import React, { useEffect, useState } from "react";
import "./ScrollWord.scss";

const ScrollWord = ({ title, wordsInput }) => {
  const [words, setWords] = useState(wordsInput);

  useEffect(() => {
    if (wordsInput) {
      setWords(wordsInput);
    }
  }, [wordsInput]);
  return (
    <header class="ms-header">
      <h1 class="ms-header__title">
        {title}{" "}
        <div class="ms-slider">
          <ul class="ms-slider__words">
            {words.map((item, index) => {
              return (
                <li key={index} class="ms-slider__word">
                  {item}
                </li>
              );
            })}
            {/* <li class="ms-slider__word">simple</li>
            <li class="ms-slider__word">easy</li>
            <li class="ms-slider__word">powerful</li>
            <li class="ms-slider__word">simple</li> */}
          </ul>
        </div>
      </h1>
    </header>
  );
};

export default ScrollWord;
