import React from "react";
import logo from "../../assets/staax-orange.png";
import { COLORS } from "../../theme";
import useWindowDimensions from "../LottieTextSection/useWindowDimensions";

const Privacy = () => {
  const { width } = useWindowDimensions();

  const isMobile = width <= 600;
  return (
    <React.Fragment>
      <div
        style={{
          position: "fixed",
          top: 0,
          width: "100%",
          backgroundColor: COLORS.dark,
          height: "55px",
        }}
      >
        <a href="/">
          <img
            className="topnav-logo"
            src={logo}
            alt="staax"
            style={{
              height: "35px",
              position: "fixed",
              top: "10px",
              left: "10px",
            }}
          />
        </a>
      </div>
      <div
        style={
          isMobile
            ? {
                color: "white",
                marginTop: "100px",
                marginLeft: "5%",
                marginRight: "5%",

                textAlign: "left",
              }
            : {
                color: "white",
                marginTop: "100px",
                marginLeft: "25%",
                marginRight: "25%",

                textAlign: "left",
              }
        }
      >
        <h1 style={{ textAlign: "center" }}>Privacy Policy</h1>
        <h2>
          Effective Date: Sept 1, 2021
          <br />
          Last Updated: Sept 20, 2022
        </h2>
        <p>
          At Staax App, Inc (“Staax,” “we, ”or “us”), we have established a
          Privacy Policy to protect the information that we secure for business
          purposes. We are committed to protecting the personal information that
          we have obtained about you. This Privacy Policy describes how we
          collect, use, share, and protect your personal information. By
          accessing or using our website, mobile application, (collectively, the
          “Site”) or services, you acknowledge that you have read, understood,
          and agree to this policy. If you cannot agree to this policy, then
          please do not use our Site or services. To further understand our
          policy, please review the following details:
        </p>
        <h3>Information Collected from You</h3>
        <p>
          This information may include: Contact information such as name, email
          address, home address, phone Billing information such as credit card
          number, and billing address Financial information such as bank or
          brokerage account numbers, types of investments Social Security number
          Credential information that you create to access our Site or services
          such as user name, password, and personal identification number
          (“PIN”) Other information that you voluntarily provide such as your
          profile picture and the content of any videos you upload to the Site
          Other information that you voluntarily give us access such as the
          information stored in your Contacts You have the ability to review and
          edit some of the information collected about you through the “Personal
          Information” section of the Site. However, you may need to contact us
          directly to review and edit certain sensitive personal information.
        </p>
        <h3>Referral Program</h3>
        <p>
          If you wish to refer friends or family members to Staax, you may share
          your referral link individually with those friends or family members.
          Staax does not directly collect any personal information from your
          friends or family members when you share your referral link with them.
          Personal information is only collected if the referral chooses to sign
          up with Staax. You will, however, receive certain communications from
          us about your referrals. For example, you will be notified if your
          friend or family member signs up for Staax. We may also use the
          referral link for the purposes of crediting their/your account with
          any referral offer to which you may both be eligible. We collect
          certain information automatically through your use of our services,
          such as which of the pages on our Site that you access, the frequency
          of access, and what you click on while on our Site. We collect
          information about the device you are using to access the Site, such as
          hardware model, operating system, application version number, browser,
          and IP addresses. We place "cookies" on your computer or device when
          you use our Site. Cookies are small text files that we place in your
          computer or device to track your usage of our Site. Other information
          you provide to us may be linked to the data stored in the cookie. A
          cookie assigns a unique numerical identifier to your browser or
          device, and may enable us to recognize you as the same user who has
          previously used our Site, and relate your use of the Site to other
          information about you.
        </p>
        <h3>Analytics</h3>
        <p>
          We may allow third parties to collect information about the users of
          our Site. Such third parties may collect Personal Information about
          your online activities over time and across different websites when
          you use our Site. We do not control these third parties, who may
          engage in practices that differ from those described in this Privacy
          Policy. The Site also uses Google Analytics, a web analytics service
          provided by Google, Inc. (“Google”). Google Analytics may set cookies
          on your browser or mobile device or read cookies that are already
          there to collect information. Google Analytics may also receive
          information about you from apps you have downloaded that partner with
          Google. Google Analytics collects information such as how often you
          use the Site and how you use the Site. We use the information provided
          by Google Analytics to improve the Site and service. For more
          information regarding how Google collects, uses and shares your
          information and how to opt out of certain uses please visit
          http://www.google.com/policies/privacy/partners/. By using the Site,
          you consent to the processing of data about you by Google as described
          here and in Google's privacy policy.
        </p>
        <h3>Use of Personal Information</h3>
        <p>
          We use your personal information to provide you with our services. For
          sender, we use your personal information to process your payment to
          your intended recipient. We use your personal information if you
          choose to subscribe to our newsletter or sign up for our waitlist. We
          also use your personal information to comply with regulatory
          requirements, prevent fraud, enforce this Privacy Policy and any other
          agreements you have entered into with us, for any purpose for which
          you provide consent, and for any other legitimate business purposes.
          We also use personal information to communicate with you about your
          account, such as to enable security functions such as two-factor
          authentication or to notify you about payments or other account
          activities. Such communications may be made by email or, with your
          consent, by sending text messages to your mobile device. We may share
          your personal information with analytics providers to understand how
          our Site and services are being used. We may also share your financial
          information with our transaction providers in order to process your
          transactions. We may share personal information when required by law
          or legal process, and we will endeavor to ask for and obtain your
          permission in such situations. We may also be required to obtain your
          authorization before disclosing certain types of personal information.
          We may share personal information if we believe it is necessary in
          order to detect, investigate, prevent, or take action against illegal
          activities, fraud, or situations involving potential threats to the
          rights, property, or personal safety of any person. If we are involved
          in a merger, acquisition, financing due diligence, reorganization,
          bankruptcy, receivership, sale of assets, or transition of service to
          another provider, your personal information may be sold or transferred
          as part of such a transaction as permitted by law and/or contract. We
          cannot control how such entities may use or disclose such personal
          information. We may share your personal information as appropriate to
          carry out any other legitimate business purposes permitted by law,
          such as sharing with our service providers and other partners that
          provide support to us when providing services to you.
        </p>
        <h3>Payment Providers</h3>
        <p>
          Staax uses Dwolla, Inc. (“Dwolla”) to facilitate ACH transactions in
          connection with our services. If you sign up for Staax’s services, you
          expressly consent to Staax sharing your contact and billing data with
          Dwolla for the purposes of opening and supporting your account. You
          are responsible for the accuracy and completeness of that data. To
          learn more about Dwolla’s privacy practices, visit their website
          athttps://www.dwolla.com/legal/privacy. We also use Plaid
          Technologies, Inc. (“Plaid”) to gather your data from financial
          institutions. This data may include information from all your
          sub-accounts (e.g., checking, savings, and credit card) accessible
          through a single set of account credentials, even if only a single
          sub-account is designated by you. By using our Services, you grant us
          and Plaid the right, power, and authority to act on your behalf to
          access and transmit your personal and financial information from the
          relevant financial institution. You agree to your personal and
          financial information being transferred, stored, and processed by
          Plaid in accordance with the Plaid Privacy Policy
          (https://plaid.com/legal).
        </p>
        <h3>Protecting Personal Information</h3>
        <p>
          In order to protect your personal information, we maintain electronic
          and procedural safeguards, which we review regularly in keeping with
          technological advances. Accounts are protected by encryption and
          authentication. Account activity is monitored to identify unauthorized
          transactions. We use 256-bit encryption to protect and secure all of
          our user’s personal information and transaction history. We also
          restrict access to your personal information. Unfortunately, no
          security system is 100% secure, thus we do not guarantee the security
          of all personal information you provide to us via our interactions.
          Staax’s most current Privacy Policy will be available on our website
          or at the bottom of the homepage. You are encouraged to review it
          periodically to familiarize yourself with our Privacy Policy and how
          it may apply to your personal situation. If there are any material
          changes or amendments to the Privacy Policy, we will notify you by
          posting an updated Privacy Policy to the Site. Contact Us For
          additional information or questions, please contact us at:
          hello@staaxapp.com
        </p>
      </div>
    </React.Fragment>
  );
};
export default Privacy;
